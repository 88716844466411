<template>
  <a-config-provider :locale="locale">
    <div :class="{ 'bme-bm-layout-dev': environment == 'development' }" class="bme-bm-layout">
      <a-layout>
        <a-layout-header v-if="environment == 'development'" class="bme-bm-header"
          >Header
        </a-layout-header>
        <a-layout :class="{ 'product-bgc': environment != 'development' }">
          <a-layout-sider v-if="environment == 'development'" class="bme-bm-aside" width="215">
            <a-menu mode="inline">
              <my-menu :menuData="menuData"></my-menu>
            </a-menu>
          </a-layout-sider>
          <a-layout-content class="bme-bm-main">
            <router-view></router-view>
          </a-layout-content>
        </a-layout>
      </a-layout>
    </div>
  </a-config-provider>
</template>

<script>
import { ref } from 'vue'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import MyMenu from '@/components/myMenu.vue'

export default {
  name: 'App',
  components: {
    MyMenu
  },
  setup() {
    const locale = ref(zhCN)
    const environment = ref(process.env.NODE_ENV)
    const menuData = ref([
      {
        key: '1',
        title: '体检管理',
        children: [
          {
            key: '1-1',
            title: '体检产品',
            to: '/product'
          },
          {
            key: '1-2',
            title: '体检套餐',
            to: '/combo'
          },
          {
            key: '1-3',
            title: '年度体检',
            children: [
              {
                key: '1-3-1',
                title: '年度体检预约单',
                to: '/reserve'
              }
            ]
          }
        ]
      }
    ])
    return {
      environment,
      menuData,
      locale
    }
  }
}
</script>

<style lang="less">
html,
body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: 'Helvetica Neue, Helvetica, Arial, Segoe UI, PingFang SC, Source Han Sans SC, Microsoft YaHei';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

.bme-bm-layout.bme-bm-layout-dev {
  .bme-bm-header {
    background: #001529;
    padding: 0 20px;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 16px;
  }

  .bme-bm-aside {
    background: #fff;
    height: calc(100vh - 64px);
  }

  .bme-bm-main {
    width: 100%;
    height: calc(100vh - 64px);
    overflow-y: auto;
    box-sizing: border-box;
    padding: 16px;
  }
}

.bme-bm-main {
  padding: 16px;
}

#app .product-bgc {
  background-color: #fff;
}
</style>
