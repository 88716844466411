<template>
  <div>
    <template v-for="item in this.curData">
      <a-sub-menu v-if="item.children" :key="item.key" :title="item.title" @click="goTo(item.to)">
        <my-menu :menuData="item.children"></my-menu>
      </a-sub-menu>
      <a-menu-item v-else :key="item.key" @click="goTo(item.to)">{{ item.title }}</a-menu-item>
    </template>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'MyMenu',
  props: {
    menuData: {
      type: Array
    }
  },
  setup(props) {
    const curData = ref(props.menuData)
    return {
      curData
    }
  },
  methods: {
    goTo(to) {
      if (!to) return
      this.$router.push(to)
    }
  }
}
</script>

<style></style>
