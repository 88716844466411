import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: 'product'
  },
  // 体检管理
  //体检套餐
  {
    path: '/product',
    name: 'product',
    component: () => import('../views/physicalExaminationManagement/product'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/combo',
    name: 'combo',
    component: () => import('../views/physicalExaminationManagement/product/combo'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/combo/detail',
    name: 'comboDetail',
    component: () => import('../views/physicalExaminationManagement/product/combo/detail')
  },
  {
    path: '/comboType',
    name: 'comboType',
    component: () => import('../views/physicalExaminationManagement/product/combo/comboType'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/configure',
    name: 'configure',
    component: () => import('../views/physicalExaminationManagement/product/combo/configure'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/comboProduct',
    name: 'comboProduct',
    component: () => import('../views/physicalExaminationManagement/product/combo/product'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reserve',
    name: 'reserve',
    component: () => import('../views/physicalExaminationManagement/annualInvoice/reserve'),
    meta: {
      requiresAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
